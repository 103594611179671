import React,{memo, useContext,useEffect,useState,useRef} from 'react'
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,TABLE_TYPES,OF_ZOOM_TYPE, OF_TIME_FRAME,OF_BOTTOM_COL_LIST, CANDLE_BOTTOM_COL, DEFAULT_CANDLE_TIME_FRAME, USER_ROLE_FIELDS, USER_RESOURCES_LIST } from '../../../common/utility/constant';
import { Snackbar,Divider,Alert,CircularProgress,InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel, Link ,Checkbox,useTheme} from '@mui/material';
import {Grid, Box,Typography,Select as MUISelect,ListSubheader,TextField,InputAdornment,FormControl,MenuItem} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsFooter from './settings_footer';
import { findIndex } from '@amcharts/amcharts5/.internal/core/util/Array';
import { VP_TYPE,VA_TYPE, CANDLE_BOTTOM_VIEW_TYPE, MAIN_PANEL_ON_BOTTOM_PANEL, BOTTOM_PANEL_HEIGHT_VAL, BOTTOM_PANEL_VIEWS, INFO_PANEL_VIEWS, ROTATING_COT_VAL } from '../../../common/utility/mp_chart_constants';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { createDictList, getInfoPanelViewName, transformAndReverseMap } from '../../../common/utility/helperFunc';
import Modal from '@mui/material/Modal';

import { List } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import the styles for the List component
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsFooterPopup from './settings_footer_popup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import useAuth from '../../../common/hooks/useAuth';
import { Select,ConfigProvider } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { deregisterFCMToken, requestNotificationPermission } from '../../../firebase';


const { Option } = Select;

// const OF_BOTTOL_COL={Volume:true,VPOC:true,"VPOC Vol":true,COT:true,OI:true,dOI:true};
// const OF_BOTTOL_COL_LIST=["Volume","VPOC","VPOC Vol","COT","OI","dOI"];
const style = {
    position: 'absolute',
    top: '5%',
    left: '10%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
    overflow: 'scroll',
  };

const containsText = (text, searchText) =>{
  return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}


 const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 80,
       autoFocus: false
     },
   },
 };

 const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
const CandleSettings = (props) => {
  const from = "/login";
  const { auth } = useAuth();
  const theme=useTheme();
  const [modalOpen, setModalOpen] = React.useState(props.open);
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = React.useState("");
  const inputRef = React.useRef();
  const [options,setOptions]=useState();
  const { userSettings, setUserSettings,UIData } = useContext(UserSettingsContext);
  const [selectedInstrument, setSelectedInstrument] = React.useState(userSettings.data.default_instrument_candle?userSettings.data.default_instrument_candle:userSettings.data.default_instrument);
  const [showGlobalVolumeProfile,setShowGlobalVolumeProfile ] =useState(userSettings.data.show_global_vp_canlde!=undefined?userSettings.data.show_global_vp_canlde:true);
  const [showAllVolumeProfile,setShowAllVolumeProfile ] =useState(userSettings.data.show_all_vp);
  const [showGlobalVolumeNumberProfile,setShowGlobalVolumeNumberProfile ] =useState(userSettings.data.show_global_vp_numbers_canlde!=undefined?userSettings.data.show_global_vp_numbers_canlde:false);
  const [showAllVolumeNumber,setShowAllVolumeNumber ] =useState(userSettings.data.show_profile_vp_numbers);
  const [showUiNpocKrl,setShowUiNpocKrl ] =useState(userSettings.data.show_ui_npoc_krl!=undefined?userSettings.data.show_ui_npoc_krl:true);
  const [showKrl,setShowKrl ] =useState(userSettings.data.show_krl!=undefined?userSettings.data.show_krl:false);
  const [showCustomKrl,setShowCustomKrl ] =useState(userSettings.data.custom_krl_display!=undefined?userSettings.data.custom_krl_display:false);
  const [customKrlMultipleAnchor,setCustomKrlMultipleAnchor ] =useState(userSettings.data.custom_krl_overlapping_anchoring!=undefined?userSettings.data.custom_krl_overlapping_anchoring:false);
  const [anchorVwap2sdDisplay,setAnchorVwap2sdDisplay ] =useState(userSettings.data.show_2sd_band_custom_anchored_krl!=undefined?userSettings.data.show_2sd_band_custom_anchored_krl:false);
  const [showOFNPOC,setShowOFNPOC ] =useState(userSettings.data.show_ui_npoc_krl_of!=undefined?userSettings.data.show_ui_npoc_krl_of:true);
  const [showOFMetadata,setShowOFMetadata ] =useState(userSettings.data.show_metadata_marker_of!=undefined?userSettings.data.show_metadata_marker_of:true);
  const [vpType,setVPType]=useState(userSettings.data.vp_type)
  const [vaType,setVAType]=useState(userSettings.data.va_type)
  const [maxCompositeDays,setMaxCompositeDays]=useState(userSettings.data.max_ndays_for_composite)
  const [defaultLineChartNDays,setDefaultLineChartNDays]=useState(userSettings.data.default_lc_ndays)
  const [autosaveInterval,setAutosaveInterval]=useState(userSettings.data.autosave_interval)
  const [numberFormatLocale,setNumberFormatLocale]=useState(userSettings.data.number_format_locale)
  const [dataTypeContracts,setDataTypeContracts]=useState(userSettings.data.data_in_contracts);
  const [ordeflowZoomType,setOrdeflowZoomType]=useState(userSettings.data.of_zoom_type?userSettings.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC)
  const [showLivePanel, setShowLivePanel]=useState(userSettings.data.show_live_panel_of!=undefined?userSettings.data.show_live_panel_of:true);
  const [showBottomeTable, setShowBottomTable]=useState(userSettings.data.show_bottom_table_of!=undefined?userSettings.data.show_bottom_table_of:true);
  const [selectedTpo, setSelectedTpo] = React.useState(3);
  const [ofBottomCol,setOfBottomCol]=useState(userSettings.data.of_bottom_col_hidden_list?userSettings.data.of_bottom_col_hidden_list:[]);
  const [candleBottomCol,setCandleBottomCol]=useState(userSettings.data.candle_bottom_col?userSettings.data.candle_bottom_col:CANDLE_BOTTOM_VIEW_TYPE.VOLUME);
  // const [candleMainPanelView,setCandleMainPanelView]=useState(userSettings.data.candle_main_panel_view?userSettings.data.candle_main_panel_view:MAIN_PANEL_ON_BOTTOM_PANEL.HIDE);
  const [candleBottomPanelView,setCandleBottomPanelView]=useState(userSettings.data.panel1_view_candle?userSettings.data.panel1_view_candle:BOTTOM_PANEL_VIEWS.TOP_LAYER);
  const [value, setValue] = useState(userSettings.data.panel1_height_candle?userSettings.data.panel1_height_candle:BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART"]);
  const [selectedTimeFrame,setSelectedTimeFrame]=useState(userSettings.data.default_tf_candle?userSettings.data.default_tf_candle:DEFAULT_CANDLE_TIME_FRAME)
  const [infoPanelView,setInfoPanelView]=useState(userSettings.data[getInfoPanelViewName(props.plot)]?userSettings.data[getInfoPanelViewName(props.plot)]:INFO_PANEL_VIEWS.EXPANDED);
  const [errorCot, setErrorCot] = useState(false);
  const [errorCot2, setErrorCot2] = useState(false);
  const isOFResourceAccess=(auth.resources.find(el=>el==USER_RESOURCES_LIST.OF_INDEX))?true:false;
  const isProUser=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false;
  const isCotAllowed=(isOFResourceAccess && isProUser );
  const [rotatingCot, setRotatingCot] = useState(userSettings.data.rotating_cot_candle?userSettings.data.rotating_cot_candle:ROTATING_COT_VAL["default"]);
  const [rotatingCot2, setRotatingCot2] = useState(userSettings.data.rotating_cot2_candle?userSettings.data.rotating_cot2_candle:ROTATING_COT_VAL["default2"]);
  
  const isPro2User=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false;
  const selectRef = useRef();
  const [browserPushNotification, setBrowserPushNotification]=useState(userSettings.data.browser_push_notifications!=undefined?userSettings.data.browser_push_notifications:false);
  const [isNotificationChanged,setNotificationChanged]=useState(false);



  const handleChange = (event,data) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=BOTTOM_PANEL_HEIGHT_VAL["min"]  && newValue <= BOTTOM_PANEL_HEIGHT_VAL["max"])) {
      setValue(newValue);
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      newData[index].panel1_height=event.target.value;

      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
    }
  };

  const handleChange2 = (event,data) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=BOTTOM_PANEL_HEIGHT_VAL["min"]  && newValue <= BOTTOM_PANEL_HEIGHT_VAL["max"])) {
      setValue(newValue);
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      newData[index].panel2_height=event.target.value;

      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
    }
  };

  const handleChangeCot = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=ROTATING_COT_VAL["min"]  && newValue <= ROTATING_COT_VAL["max"])) {
      setRotatingCot(newValue);
    }
  };

  const handleChangeCot2 = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=ROTATING_COT_VAL["min"]  && newValue <= ROTATING_COT_VAL["max"])) {
      setRotatingCot2(newValue);
    }
  };

  const handleInfoPanelViewChange = (event,data) => {
    setInfoPanelView(event.target.value);
    let newData=JSON.parse(JSON.stringify(tpoGroupData));
    let index=newData.findIndex(item=>item.key==data.key)
    if(index==-1)
    return;

    newData[index].info_panel_view=event.target.value;

    setUIDataState((prevState) => ({
      ...prevState,
     tpoGroupData:newData
    }));
  };

  // const [bottomViewType,setBottomViewType]=useState(userSettings.data.candle_bottom_view)
 
  useEffect(()=>{
    if(userSettings.data.candle_map==undefined){
        userSettings.data.candle_map={};
        // userSettings.data.candle_map.NIFTY={"1":2,"3":3,"5":4};
    }
  },[])

  const autoFocus=()=>{
    const timeout = setTimeout(() => {
      if( inputRef.current)
      inputRef.current.focus();

    }, 100);

  }

  console.log(userSettings.data)

  const [uiDataState,setUIDataState]=useState({
    tpoGroupData:undefined,
    pprGroupData:undefined,
    instrumentData:undefined,
    tpoGroups:undefined,
    isTPOLoaded:false,
    tableData:undefined
  })
  const {tpoGroupData,instrumentData,isTPOLoaded,tpoGroups,tableData}=uiDataState;

  const [settingsState,setSettingsState]=useState({
    settingsData:userSettings,
    isUserSettingsLoaded:userSettings!=undefined && userSettings.data!=undefined,
    apiTableData:userSettings.data.page_wise_hidden_columns  
  })
  const {settingsData,isUserSettingsLoaded,apiTableData}=settingsState;
  const [currentSettings,setCurrentSettings]=useState()
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    // document.title = "User Settings";
    console.log("MP settings props instrument and data=",props.instrument,props.selectedInstrumentData)
  }, []);
  
  const handleModalClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };
  
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelSettings,responseDataSettings, errorSettings, loadedSettings, resetSettings,executeAPISettings ] = useAxiosWithAuth();
  const [cancelDefault,responseDataDefault, errorDefault, loadedDefault, resetDefault,executeAPIDefault ] = useAxiosWithAuth();
  const [cancelToken,responseDataToken, errorToken, loadedToken, resetToken,executeAPIToken ] = useAxiosWithAuth();

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);
  
  useEffect(() => {
    // Function to update parentWidth
    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        console.log("parent width useeffect=",width)
        setParentWidth(width);
      }
    };

    // Initial update
    updateParentWidth();

    // Attach event listener for window resize
    window.addEventListener('resize', updateParentWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, [parentRef.current]);

  const LargeList = ({ data }) => {
    const height=window.innerHeight-120;
    const isLaptop=useMediaQuery('(min-width:1024px)')
    const is760=useMediaQuery('(min-width:640px)')
    let width=0
    if( isLaptop){
      width=(window.innerWidth*8/12)-20;
    }else if(is760){
      width=Math.max((window.innerWidth*10/12)-20,760)-40;
      // width=760-40;
    }else{
      width=760-40;
    }
   

    const rowRenderer = ({ index, key, style }) => (
      <div key={data[index].name} style={style}>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
      <Typography sx={{mt:0,mr:5,fontSize:14,width:"140px"}}>{data[index].name}</Typography>
     
     <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].dailyTPO}
       MenuProps={MenuProps}
       sx={{height:"24px"}}
       onChange={(event)=>handleTPOChange(event,data[index],"daily")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].weeklyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"weekly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].monthlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"monthly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].yearlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"yearly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   </Box>
      </div>
    );
  
    console.log("width height=",width,height)
    return (
      <List
      width={width}
      height={height}
      rowCount={data.length}
      rowHeight={40}
      rowRenderer={rowRenderer}
      // autoHeight
      overscanRowCount={10}
      containerProps={{
        overflow: 'visible',
      }}
    />

    );
  };

  
  

/**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedDefault){
      if(responseDataDefault!=null){
          
          setSelectedInstrument(responseDataDefault.data.default_instrument_candle?responseDataDefault.data.default_instrument_candle:responseDataDefault.data.default_instrument);
          const tpoData=processTPOData(tpoGroups,responseDataDefault);
          const pprData=processPPRData(tpoGroups,responseDataDefault);
          console.log("OFSettings : user setting response=",responseDataDefault,tpoData,pprData,props.selectedInstrumentData)
          setUIDataState((prevState)=>({
            ...prevState,
            tpoGroupData:pprData    
          }));
        
          setSettingsState({settingsData:responseDataDefault,isUserSettingsLoaded:true,apiTableData:responseDataDefault.data.page_wise_hidden_columns});
      }
      else if(errorDefault!==null){
        setSettingsState((prevState)=>({
          ...prevState,
          isUserSettingsLoaded:true
        }))
        console.log("Error data=",errorDefault);
        setMsgState({open:true,msg:errorDefault?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorDefault?.response?.status === 401 || errorDefault?.response?.status === 403){
          console.log("status received =",errorDefault?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetDefault();
    }
  },[loadedDefault,responseDataDefault]);

  /**
 * API response handler  for user settings save
 */  
   useEffect(() => {
    if(loadedSettings){
    
      if(responseDataSettings!=null){
        setSettingsState((prevState)=>({
            ...prevState,
            settingsData:responseDataSettings
          }));
          setUserSettings(responseDataSettings);
          console.log("Settings : user setting response=",responseDataSettings)
          setMsgState({open:true,msg:responseDataSettings?.message ?? `${TEXT_MSGS.USER_SETTINGS_SAVED}`,severity:"success"});
          props.saveHandler(responseDataSettings);
          async function saveFCMToken(){
            let fcmToken=null;
            try {
              fcmToken = await requestNotificationPermission();
              console.log("Login FCM Token:", fcmToken,responseData.role);
            } catch (error) {
              console.warn("Login FCM token not retrieved. User may have denied notifications.");
            }
            if (fcmToken) {
              // await sendFcmTokenToServer(fcmToken,accessToken);
              executeAPIToken(URL.SAVE_FCM_TOKEN,"POST",{fcm_token:fcmToken})
            } else {
              console.log("Login  Skipping FCM token submission due to permission denial.");
            }
            // navigate(from, { replace: true });
          }

          if(isPro2User && isNotificationChanged){
            if(currentSettings.data.browser_push_notifications){
              // console.log("Registering FCM token");
              saveFCMToken();
            }else{
              // console.log("Degistering FCM token");
              deregisterFCMToken();
            }    
          }
       
      }
      else if(errorSettings!==null){
        console.log("Error data=",errorSettings);
        setMsgState({open:true,msg:errorSettings?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorSettings?.response?.status === 401 || errorSettings?.response?.status === 403){
          console.log("status received =",errorSettings?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetSettings();
    }
  },[loadedSettings,responseDataSettings]);

  //FCM token save reponse handler
  useEffect(() => {
    if(loadedToken){
      if(responseDataToken!=null){
          console.log("Save FCM Token=",responseDataToken)
          
      }
      else if(errorToken!==null){
       
        setMsgState({open:true,msg:errorToken?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorToken?.response?.status === 401 || errorToken?.response?.status === 403){
          console.log("status received =",errorToken?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetToken();
    }
  },[loadedToken,responseDataToken]);


    //execute the data fetch on the component mount
    useEffect(()=>{
      // let data={
      //   "symbol": "NIFTY",
      //   "instruments": 1,
      //   // "tpoList": 1,
      //   // "snakechart_data": 1
      //   "user_settings_form" : 1
      // }
      // executeAPI(URL.MPCHART_UIDATA,"POST",data); 
      let responseData=UIData;
      const filteredInstruments = responseData.instruments.filter(item => !item.series && item.cc==true && !item.name.endsWith("_SPREAD"));
      const pprData=processPPRData(responseData.tpo_groups,userSettings);
      setUIDataState({tpoGroupData:pprData,tpoGroups:responseData.tpo_groups,instrumentData:filteredInstruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
         
    //  setUIDataState({tpoGroupData:tpoData,tpoGroups:responseData.tpo_groups,instrumentData:filteredInstruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
    
     setOptions (filteredInstruments);
    },[]);
  
    const processTPOData=(data,userSettings)=>{
      let processedData=[];
      for (const key in data) {
        // console.log(key, data[key])
        let temp=data[key];
        temp.key=key;
        //  temp.name=key;
        temp.name=key.replace(/_OPT/,' OPTIONS');
        // console.log("daily tpo in settings =",userSettings.data.tf_tpo_map[key].daily);
        if(key in userSettings.data.tf_tpo_map){
          temp.dailyTPO=userSettings.data.tf_tpo_map[key].daily
          temp.weeklyTPO=userSettings.data.tf_tpo_map[key].weekly
          temp.monthlyTPO=userSettings.data.tf_tpo_map[key].monthly
          temp.yearlyTPO=userSettings.data.tf_tpo_map[key].yearly
          processedData.push(temp);
        } else {
          temp.dailyTPO=data[key].default_tpo;
          temp.weeklyTPO=data[key].default_tpo*data[key].tf_factor.weekly;
          temp.monthlyTPO=data[key].default_tpo*data[key].tf_factor.monthly;
          temp.yearlyTPO=data[key].default_tpo*data[key].tf_factor.yearly;
          processedData.push(temp);
        }

      }
      return processedData;
    }

    const processPPRData=(data,userSettings)=>{
        let processedData=[];
        for (const key in data) {
          // console.log(key, data[key])
          let temp=data[key];
          temp.key=key;
          //  temp.name=key;
          temp.name=key.replace(/_OPT/,' OPTIONS');
        //   console.log("OFSETTINGS processPPRData",temp);
          // console.log("daily tpo in settings =",userSettings.data.tf_tpo_map[key].daily);
          if(key in userSettings.data.candle_map){
            console.log("INSIDEEEEEE IF")
            let isOPT=key.includes("_OPT");
            temp.panel1_bottom_col=userSettings.data.candle_map[key].panel1_bottom_col?userSettings.data.candle_map[key].panel1_bottom_col:(isOPT?CANDLE_BOTTOM_VIEW_TYPE.VOLUME: CANDLE_BOTTOM_VIEW_TYPE.VOLUME)
            temp.panel1_view_type=userSettings.data.candle_map[key].panel1_view_type
            temp.panel1_height=userSettings.data.candle_map[key].panel1_height
            temp.panel2_bottom_col=userSettings.data.candle_map[key].panel2_bottom_col?userSettings.data.candle_map[key].panel2_bottom_col:(isOPT?CANDLE_BOTTOM_VIEW_TYPE.DOI: CANDLE_BOTTOM_VIEW_TYPE.COT)
            temp.panel2_view_type=userSettings.data.candle_map[key].panel2_view_type?userSettings.data.candle_map[key].panel2_view_type:BOTTOM_PANEL_VIEWS.TOP_LAYER;
            temp.panel2_height=userSettings.data.candle_map[key].panel2_height?userSettings.data.candle_map[key].panel2_height:BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART2"];
            temp.info_panel_view=userSettings.data.candle_map[key].info_panel_view
            processedData.push(temp);
          } else {
            console.log("INSIDEEEEEE ELSE")
            console.log("test candlesettings temp else=",temp,data[key],key,key.includes("_OPT"));
            let isOPT=key.includes("_OPT");
            temp.panel2_bottom_col=isOPT?CANDLE_BOTTOM_VIEW_TYPE.DOI: CANDLE_BOTTOM_VIEW_TYPE.COT;
            temp.panel1_view_type=BOTTOM_PANEL_VIEWS.TOP_LAYER;
            temp.panel1_height=BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART"];
            temp.panel1_bottom_col=isOPT?CANDLE_BOTTOM_VIEW_TYPE.VOLUME: CANDLE_BOTTOM_VIEW_TYPE.VOLUME;
            temp.panel2_view_type=BOTTOM_PANEL_VIEWS.TOP_LAYER;
            temp.panel2_height=BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART2"];
            temp.info_panel_view=INFO_PANEL_VIEWS.EXPANDED;
            processedData.push(temp);
          }
  
        }
        return processedData;
      }

    /**
   * API response handler  
   */  
     useEffect(() => {
      if(loaded){
        if(responseData!=null){
          responseData.instruments = responseData.instruments.map((item) =>
            transformAndReverseMap(item)
          );
            console.log("OFSettings OFCHart UI Data response=",responseData)
          const tpoData=processTPOData(responseData.tpo_groups,userSettings);
          const pprData=processPPRData(responseData.tpo_groups,userSettings);
          console.log("OFSettings 2 MPCHart UI Data response=",responseData,tpoData,pprData,props.selectedInstrumentData)
           setUIDataState({tpoGroupData:pprData,tpoGroups:responseData.tpo_groups,instrumentData:responseData.instruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
           setOptions (responseData.instruments.filter(item=>item.of==true));
        }
        else if(error!==null){
          setUIDataState({tpoGroupData:undefined,tpoGroups:undefined,instrumentData:undefined,isTPOLoaded:true,tableData:undefined});
          console.log("Error data=",error);
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }
      }
      reset();
      }
    },[loaded,responseData]);
    
    useEffect(()=>{
      console.log("searchText=", searchText );
      if(instrumentData!=undefined){
        if(searchText=="")
        setOptions(instrumentData)
        else{
          const temp=instrumentData.filter((option) => containsText(option, searchText))
          setOptions(temp);
        }
      }
    },[searchText])
  
    const handleInstrumentChange = (event) => {
      // setSelectedInstrument(event.target.value);
      setSelectedInstrument(event);
    }  

    const handleTimeframeChange = (event) => {
      setSelectedTimeFrame(event.target.value);
    }  
    const handleVPChange = (event) => {
      setVPType(event.target.value);
    };

   

    const handleBottomViewChangeNew= (event,data) => {
      if(event.target.value==CANDLE_BOTTOM_VIEW_TYPE.COT && isCotAllowed==false){
        setErrorCot(true);
        return;
      }
      setErrorCot(false);
      setCandleBottomCol(event.target.value);

      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      newData[index].panel1_bottom_col=event.target.value;

      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));

      
    };

    const handleBottomViewChangeNew2= (event,data) => {
      if(event.target.value==CANDLE_BOTTOM_VIEW_TYPE.COT && isCotAllowed==false){
        setErrorCot2(true);
        return;
      }
      setErrorCot2(false);
      setCandleBottomCol(event.target.value);

      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      newData[index].panel2_bottom_col=event.target.value;

      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));

      
    };

   

    const handleBottomPanelViewChange = (event,data) => {
      setCandleBottomPanelView(event.target.value);
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      newData[index].panel1_view_type=event.target.value;

      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
    };

    const handleBottomPanelViewChange2 = (event,data) => {
      setCandleBottomPanelView(event.target.value);
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      newData[index].panel2_view_type=event.target.value;

      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
    };

    const handleVAChange = (event) => {
      setVAType(event.target.value);
    };

    const handleLocaleChange = (event) => {
      setNumberFormatLocale(event.target.value);
    };

    const handleDataTypeChange = (event) => {
      console.log(event.target.value);
      setDataTypeContracts(event.target.value==="true");
    };

    const handleOFZoomChange = (event) => {
      setOrdeflowZoomType(event.target.value);
    };

    const handleTPOChange=(event,data,type)=>{
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      if(type=="min1"){
        newData[index].panel1_bottom_col=event.target.value;
      }
      else if(type=="min3"){
        newData[index].panel1_view_type=event.target.value;
      }
      else if(type=="min5"){
        newData[index].panel1_height=event.target.value;
      }
      else if(type=="min15"){
        newData[index].info_panel_view=event.target.value;
      }
      else if(type=="min30"){
        newData[index].min30TPO=event.target.value;
      }
      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
      
    }

    const disableAutosave=()=>{
      setAutosaveInterval(0);
    }

    const saveSettings=()=>{
        // let newData=JSON.parse(JSON.stringify(userSettings));
        let newData={};
        newData.data={};
    
        newData.data.default_instrument_candle=selectedInstrument
        newData.data.candle_map=userSettings.data.candle_map;
        if(newData.data.candle_map==undefined)
        newData.data.candle_map={};


      for(let i=0;i<tpoGroupData.length;i++){
        if(tpoGroupData[i].key=="NIFTY"){
          console.log("Candle Saved setting NIFTY TPOGroupdata=",tpoGroupData[i]);
        }
        if(tpoGroupData[i].key in newData.data.candle_map){
          newData.data.candle_map[tpoGroupData[i].key].panel1_bottom_col=tpoGroupData[i].panel1_bottom_col;
          newData.data.candle_map[tpoGroupData[i].key].panel1_view_type=tpoGroupData[i].panel1_view_type;
          newData.data.candle_map[tpoGroupData[i].key].panel1_height=parseInt(tpoGroupData[i].panel1_height);
          newData.data.candle_map[tpoGroupData[i].key].panel2_bottom_col=tpoGroupData[i].panel2_bottom_col;
          newData.data.candle_map[tpoGroupData[i].key].panel2_view_type=tpoGroupData[i].panel2_view_type;
          newData.data.candle_map[tpoGroupData[i].key].panel2_height=parseInt(tpoGroupData[i].panel2_height);
          newData.data.candle_map[tpoGroupData[i].key].info_panel_view=tpoGroupData[i].info_panel_view;
          
      
        } else {
          newData.data.candle_map[tpoGroupData[i].key] = {}
          let isOPT=tpoGroupData[i].key.includes("_OPT");
          newData.data.candle_map[tpoGroupData[i].key].panel1_bottom_col=tpoGroupData[i].panel1_bottom_col;
          newData.data.candle_map[tpoGroupData[i].key].panel1_view_type=tpoGroupData[i].panel1_view_type;
          newData.data.candle_map[tpoGroupData[i].key].panel1_height=parseInt(tpoGroupData[i].panel1_height);
          newData.data.candle_map[tpoGroupData[i].key].panel2_bottom_col=tpoGroupData[i].panel2_bottom_col;
          newData.data.candle_map[tpoGroupData[i].key].panel2_view_type=tpoGroupData[i].panel2_view_type;
          newData.data.candle_map[tpoGroupData[i].key].panel2_height=parseInt(tpoGroupData[i].panel2_height);
          newData.data.candle_map[tpoGroupData[i].key].info_panel_view=tpoGroupData[i].info_panel_view;
     
         
        }
      }
   
        newData.data.show_global_vp_canlde=showGlobalVolumeProfile;
        newData.data.show_global_vp_numbers_canlde=showGlobalVolumeNumberProfile;
        // newData.data.candle_bottom_col=candleBottomCol;
        // newData.data.candle_main_panel_view=candleMainPanelView;
        // newData.data.panel1_view_candle=candleBottomPanelView;
        // newData.data.panel1_height_candle=parseInt(value);
        newData.data.default_tf_candle=selectedTimeFrame;
        // newData.data[getInfoPanelViewName(props.plot)]=infoPanelView;
        if(isPro2User){
          newData.data.rotating_cot_candle=parseInt(rotatingCot)
          newData.data.rotating_cot2_candle=parseInt(rotatingCot2)
        }
        
        // newData.data.show_metadata_marker_of=showOFMetadata;

        // newData.data.show_ui_npoc_krl_of=showOFNPOC;
        // newData.data.of_zoom_type=ordeflowZoomType;


      // newData.data.show_live_panel_of=showLivePanel;
      // newData.data.show_bottom_table_of=showBottomeTable;

      newData.data.number_format_locale=numberFormatLocale;
      newData.data.data_in_contracts=dataTypeContracts;

      // newData.data.of_bottom_col_hidden_list=ofBottomCol;
      if(isPro2User)
        newData.data.browser_push_notifications=browserPushNotification;
     
      
      setCurrentSettings(newData);
      console.log("Candle Saved setting=",newData);

      let data={"data":newData};
      // newData.data.candle_map={};  
      executeAPISettings(URL.UPDATE_USER_SETTING,"POST",newData.data);

      
     
    }
    useEffect(()=>{
      setSelectedInstrument(settingsData.data.default_instrument_candle?settingsData.data.default_instrument_candle:settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp_canlde!=undefined?settingsData.data.show_global_vp_canlde:true);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers_canlde!=undefined?settingsData.data.show_global_vp_numbers_canlde:false);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      // setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      setShowLivePanel(settingsData.data.show_live_panel_of!=undefined?settingsData.data.show_live_panel_of:true);
      setShowBottomTable(settingsData.data.show_bottom_table_of!=undefined?settingsData.data.show_bottom_table_of:true);
      if(isPro2User)
        setBrowserPushNotification(settingsData.data.browser_push_notifications!=undefined?settingsData.data.browser_push_notifications:false);

      
    },[settingsData])

    const discardChanges=()=>{
      setUIDataState((prevState) => ({
        ...prevState,
       isTPOLoaded:false,
      }));
      setOptions (undefined);
      setSelectedInstrument(settingsData.data.default_instrument_candle?settingsData.data.default_instrument_candle:settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp_canlde!=undefined?settingsData.data.show_global_vp_canlde:true);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers_canlde!=undefined?settingsData.data.show_global_vp_numbers_canlde:false);
      setCandleBottomCol(settingsData.data.candle_bottom_col?settingsData.data.candle_bottom_col:CANDLE_BOTTOM_VIEW_TYPE.VOLUME);
      // setCandleMainPanelView(settingsData.data.candle_main_panel_view?settingsData.data.candle_main_panel_view:MAIN_PANEL_ON_BOTTOM_PANEL.HIDE);
      setCandleBottomPanelView(settingsData.data.panel1_view_candle?settingsData.data.panel1_view_candle:MAIN_PANEL_ON_BOTTOM_PANEL.SHOW);
      setValue(settingsData.data.panel1_height_candle? settingsData.data.panel1_height_candle:BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART"])
      // setValue(settingsData.data.panel2_height_candle? settingsData.data.panel2_height_candle:BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART2"])
     
      if(isPro2User){
        setRotatingCot(settingsData.data.rotating_cot_candle?settingsData.data.rotating_cot_candle:ROTATING_COT_VAL["default"])
        setRotatingCot2(settingsData.data.rotating_cot2_candle?settingsData.data.rotating_cot2_candle:ROTATING_COT_VAL["default"])
      }
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      
      setShowLivePanel(settingsData.data.show_live_panel_of!=undefined?settingsData.data.show_live_panel_of:true);
      setShowBottomTable(settingsData.data.show_bottom_table_of!=undefined?settingsData.data.show_bottom_table_of:true);
      setOfBottomCol(settingsData.data.of_bottom_col_hidden_list?settingsData.data.of_bottom_col_hidden_list:[]);
      setSelectedTimeFrame(userSettings.data.default_tf_of?userSettings.data.default_tf_of:DEFAULT_CANDLE_TIME_FRAME);
      setInfoPanelView(settingsData.data[getInfoPanelViewName(props.plot)]?settingsData.data[getInfoPanelViewName(props.plot)]:INFO_PANEL_VIEWS.EXPANDED);
      if(isPro2User)
        setBrowserPushNotification(settingsData.data.browser_push_notifications!=undefined?settingsData.data.browser_push_notifications:false);

      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:settingsData.data.page_wise_hidden_columns  
      }))
     
      const tpoData=processTPOData(tpoGroups,settingsData);
      // console.log(tpoData)
      setUIDataState({tpoGroupData:tpoData,tpoGroups:tpoGroups,instrumentData:instrumentData, isTPOLoaded:true,tableData:tableData});
      
    }

    const resetToDefault=()=>{
      setSettingsState((prevState)=>({
        ...prevState,
        isUserSettingsLoaded:false
      }))
      // /api/user/settings?reset=true
      executeAPIDefault(`${URL.USER_SETTING}?reset=true`,"GET",{});

    }

/**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

  const handleColumnChange=(event,col)=>{
    if(event.target.checked){
      console.log("col,type",col)
      let data=JSON.parse(JSON.stringify(ofBottomCol));
      data = data.filter(item => item !== col)
      console.log("unchecked=",data);
      setOfBottomCol(data);
      
    }else{
      let data=JSON.parse(JSON.stringify(ofBottomCol));
      data.push(col)
      console.log("unchecked=",data);
      setOfBottomCol(data);
     }
  }

  const handleNotificationChange=(val)=>{
    setBrowserPushNotification(val);
    setNotificationChanged(true)
  
  }
 

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
       <ConfigProvider
    theme={{
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
      components: {
        
        Select: {
          colorPrimary: '#0d203f',
          colorBgContainer:"#0000ff",
          optionActiveBg:"#22314E",
          colorText:"#FFFFFF",
          optionSelectedBg:"#35445E",
          optionSelectedColor:"#FFFFFF",
          selectorBg:"transparent",
          colorTextPlaceholder:"#ffffff",
          colorIcon:"#ff0000",
          colorTextDisabled:"#899499"
          // algorithm: true, // Enable algorithm
        },
        
      },
    }}
  >
    <>
    {isTPOLoaded && isUserSettingsLoaded && options!=undefined?
     <Box sx={style} >
         <CancelOutlinedIcon
                onClick={() => handleModalClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(5% - 12px)`,left: `calc(90% - 13px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            />
    <Grid container spacing={2}>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    <Grid item mobile={12} tablet={10} laptop={8} sx={{minWidth:"760px",ml:2,mr:2}}>
    
    <Box sx={{display:"flex",mr:1,mt:2}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Candlestick - Instrument Specific Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Settings specific to the selected instrument in Candlestick Chart</Typography>
                {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                {"It's very engaging. Right?"} */}
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"24px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>

      <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:6,pr:2,borderRadius:5,width:"100%"}}>
      <Box display="flex" justifyContent="flex-start" flexDirection={"column"} sx={{mt:2,mb:0,ml:2,pr:2,width:"100%"}}>
      {tpoGroupData.filter(item=>item.key==props.selectedInstrumentData.tpo_group).map((item,index) => (
        <>
        <Typography sx={{mr:1,mt:2,fontSize:18,fontWeight:700,ml:4}}>{item.name}</Typography>
        
        <Box display="flex" justifyContent="flex-start" sx={{mt:1,mb:0,ml:4,width:"100%"}}>
              <Box sx={{width:"272px",display:"flex"}}>
                  <Typography sx={{mr:1,mt:2,fontSize:14}}>Top Left Info Panel View</Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Info Panel Visibility (Top Left Panel)</Typography>
                        <li>Expanded: Default Info panel will be exapnded view.</li>
                        <li>Collapsed: Default Info panel will be collapsed view.</li>
                      </React.Fragment>
                    }
                  >
                  <InfoIcon sx={{marginTop:"14px"}}></InfoIcon>
                </HtmlTooltip>
              </Box>
      
            <RadioGroup
            row
            sx={{ml:1,mt:1}}
            size='small'
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
               <FormControlLabel value={INFO_PANEL_VIEWS.EXPANDED}
              control={<Radio checked={item.info_panel_view === INFO_PANEL_VIEWS.EXPANDED} onChange={(event)=>handleInfoPanelViewChange(event,item)} size="small" />} 
              label={<Typography sx={{fontSize:14}}>Expanded</Typography>} />
               <FormControlLabel value={INFO_PANEL_VIEWS.COLLAPSED}
              control={<Radio checked={item.info_panel_view === INFO_PANEL_VIEWS.COLLAPSED} onChange={(event)=>handleInfoPanelViewChange(event,item)}size="small" />} 
              label={<Typography sx={{fontSize:14}}>Collapsed</Typography>} />
             
          </RadioGroup>
        </Box>
        <Box display="flex" justifyContent="flex-start" sx={{mt:2,mb:0,ml:4,width:"100%"}}>
        
        <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:2,fontSize:14}}>Panel #2 Display</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Panel #2 Display (Upper one)</Typography>
                  What to show in the Upper bottom panel (Panel #2)?
                  {/* <li>None: No Volume profile shown by default.</li> */}
                  <li>Volume: Volume bars will be shown.</li>
                  <li>COT: COT bars will be shown.</li>
                  <li>DOI: OI change will be shown.</li>
                  
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"12px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>

      <RadioGroup
        row
        sx={{ml:1,mt:1}}
        size='small'
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {/* <FormControlLabel value={VP_TYPE.NONE}
          control={<Radio checked={vpType === VP_TYPE.NONE} onChange={handleVPChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>None</Typography>} /> */}
        <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.VOLUME}
          control={<Radio checked={item.panel2_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.VOLUME} onChange={(event)=>handleBottomViewChangeNew2(event,item)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Volume</Typography>} />
      <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.COT}
          control={<Radio checked={item.panel2_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.COT} onChange={(event)=>handleBottomViewChangeNew2(event,item)}  size="small" />} 
          label={<Typography sx={{fontSize:14}}>COT</Typography>} />
          <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.DOI}
          control={<Radio checked={item.panel2_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.DOI}  onChange={(event)=>handleBottomViewChangeNew2(event,item)}  size="small" />} 
          label={<Typography sx={{fontSize:14}}>DOI</Typography>} />
        
      </RadioGroup>
    </Box>
  
    {errorCot2 && <Typography sx={{marginLeft:"300px",mt:-1}}variant='errorText'>Oops! You don't have orderflow access, needed for COT display. <Link href='/subscription'>Upgrade</Link> your plan now to access it.</Typography>}
    
  

    <Box display="flex" justifyContent="flex-start" sx={{mt:0,mb:0,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #2 Visibilty</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Panel #2 Visibility (Upper one)</Typography>
                  
                    {/* <li>None: No Volume profile shown by default.</li> */}
                    <li>Top Layer: Show bottom panel at top of main panel.</li>
                    <li>Same Container: Main panel overlap with bottom panel.</li>
                    <li>Hidden: Hide bottom panel.</li>
                  
                    
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>

        <RadioGroup
        row
        sx={{ml:1}}
        size='small'
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
          <FormControlLabel value={BOTTOM_PANEL_VIEWS.TOP_LAYER}
          control={<Radio checked={item.panel2_view_type === BOTTOM_PANEL_VIEWS.TOP_LAYER} onChange={(event)=>handleBottomPanelViewChange2(event,item)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Top Layer</Typography>} />
          <FormControlLabel value={BOTTOM_PANEL_VIEWS.SAME_CONTAINER}
          control={<Radio checked={item.panel2_view_type === BOTTOM_PANEL_VIEWS.SAME_CONTAINER} onChange={(event)=>handleBottomPanelViewChange2(event,item)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Same Container</Typography>} />
          <FormControlLabel value={BOTTOM_PANEL_VIEWS.HIDDEN}
          control={<Radio checked={item.panel2_view_type === BOTTOM_PANEL_VIEWS.HIDDEN} onChange={(event)=>handleBottomPanelViewChange2(event,item)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Hidden</Typography>} />
      </RadioGroup>
    </Box>
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
    <Box sx={{width:"272px",display:"flex"}}>
    <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #2 Height in % (Upper one)</Typography>
      <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Panel #2 height wrt main panel height in percentage [5,60]</Typography>
                    </React.Fragment>
              }
            >
          <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
        </HtmlTooltip>
    </Box>
          <TextField
            size="small"
            label=""
            type="number"
            value={item.panel2_height}
            onChange={(event)=>handleChange2(event,item)}
            sx={{height:"25px",ml:1,padding:"1px"}}
            inputProps={{ min: BOTTOM_PANEL_HEIGHT_VAL["min"], max: BOTTOM_PANEL_HEIGHT_VAL["max"] }}
            // fullWidth
      />
    </Box>
    <Divider sx={{mt:2}}/>
        <Box display="flex" justifyContent="flex-start" sx={{mt:1,mb:0,ml:4,width:"100%"}}>
        
              <Box sx={{width:"272px",display:"flex"}}>
                  <Typography sx={{mr:1,mt:2,fontSize:14}}>Panel #1 Display</Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Panel #1 Display (Lower one)</Typography>
                        What to show in the lower panel (Panel #1)?
                        {/* <li>None: No Volume profile shown by default.</li> */}
                        <li>Volume: Volume bars will be shown.</li>
                        <li>COT: COT bars will be shown.</li>
                        <li>DOI: OI change will be shown.</li>
                        
                      </React.Fragment>
                    }
                  >
                  <InfoIcon sx={{marginTop:"12px"}}></InfoIcon>
                </HtmlTooltip>
              </Box>
      
            <RadioGroup
            row
            sx={{ml:1,mt:1}}
            size='small'
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {/* <FormControlLabel value={VP_TYPE.NONE}
              control={<Radio checked={vpType === VP_TYPE.NONE} onChange={handleVPChange} size="small" />} 
              label={<Typography sx={{fontSize:14}}>None</Typography>} /> */}
            <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.VOLUME}
              control={<Radio checked={item.panel1_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.VOLUME} onChange={(event)=>handleBottomViewChangeNew(event,item)} size="small" />} 
              label={<Typography sx={{fontSize:14}}>Volume</Typography>} />
          <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.COT}
              control={<Radio checked={item.panel1_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.COT} onChange={(event)=>handleBottomViewChangeNew(event,item)}  size="small" />} 
              label={<Typography sx={{fontSize:14}}>COT</Typography>} />
              <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.DOI}
              control={<Radio checked={item.panel1_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.DOI}  onChange={(event)=>handleBottomViewChangeNew(event,item)}  size="small" />} 
              label={<Typography sx={{fontSize:14}}>DOI</Typography>} />
            
          </RadioGroup>
        </Box>
       
        {errorCot && <Typography sx={{marginLeft:"300px",mt:-1}}variant='errorText'>Oops! You don't have orderflow access, needed for COT display. <Link href='/subscription'>Upgrade</Link> your plan now to access it.</Typography>}
        
       

        <Box display="flex" justifyContent="flex-start" sx={{mt:0,mb:0,ml:4,width:"100%"}}>
              <Box sx={{width:"272px",display:"flex"}}>
                  <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #1 Visibilty</Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Panel #1 Visibility (Lower one)</Typography>
                       
                        {/* <li>None: No Volume profile shown by default.</li> */}
                        <li>Top Layer: Show bottom panel at top of main panel.</li>
                        <li>Same Container: Main panel overlap with bottom panel.</li>
                        <li>Hidden: Hide bottom panel.</li>
                       
                        
                      </React.Fragment>
                    }
                  >
                  <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
                </HtmlTooltip>
              </Box>
      
            <RadioGroup
            row
            sx={{ml:1}}
            size='small'
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
               <FormControlLabel value={BOTTOM_PANEL_VIEWS.TOP_LAYER}
              control={<Radio checked={item.panel1_view_type === BOTTOM_PANEL_VIEWS.TOP_LAYER} onChange={(event)=>handleBottomPanelViewChange(event,item)} size="small" />} 
              label={<Typography sx={{fontSize:14}}>Top Layer</Typography>} />
              <FormControlLabel value={BOTTOM_PANEL_VIEWS.SAME_CONTAINER}
              control={<Radio checked={item.panel1_view_type === BOTTOM_PANEL_VIEWS.SAME_CONTAINER} onChange={(event)=>handleBottomPanelViewChange(event,item)} size="small" />} 
              label={<Typography sx={{fontSize:14}}>Same Container</Typography>} />
               <FormControlLabel value={BOTTOM_PANEL_VIEWS.HIDDEN}
              control={<Radio checked={item.panel1_view_type === BOTTOM_PANEL_VIEWS.HIDDEN} onChange={(event)=>handleBottomPanelViewChange(event,item)} size="small" />} 
              label={<Typography sx={{fontSize:14}}>Hidden</Typography>} />
          </RadioGroup>
        </Box>
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #1 Height in % (Lower one)</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Panel #1 height wrt main panel height in percentage [5,60]</Typography>
                         </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
              <TextField
                size="small"
                label=""
                type="number"
                value={item.panel1_height}
                onChange={(event)=>handleChange(event,item)}
                sx={{height:"25px",ml:1,padding:"1px"}}
                inputProps={{ min: BOTTOM_PANEL_HEIGHT_VAL["min"], max: BOTTOM_PANEL_HEIGHT_VAL["max"] }}
                // fullWidth
          />
        </Box>

        
      
        </>
          ))}
          
        </Box>
      </Box>     
    
    {/* Orderflow settings */}
    <Box sx={{display:"flex",mr:1,mt:0}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Candlestick - Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Global settings applicable across Candlestick Charts for all instruments in general</Typography>
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"24px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:5,pr:2,borderRadius:5,width:"100%"}}>
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
    <FormControl variant="standard" size="small" sx={{ minWidth:"80%",ml:0,mt:1 }}>
      <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:0}}>
      <Box sx={{width:"272px",display:"flex",mr:1}}>
      <Typography sx={{marginTop:"4px",mr:5,fontSize:16,width:"150px"}}>Default Instrument</Typography>
      <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Default instrument when an candlestick chart is opened from menu.</Typography>
                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                  {"It's very engaging. Right?"} */}
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"5px"}}></InfoIcon>
          </HtmlTooltip>
          </Box>
                    {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedInstrument}
                        onChange={handleInstrumentChange}
                        onOpen={autoFocus}
                        label="Instrument"
                        sx={{minWidth: 230,border:"1px solid #fff",borderColor:"#fff",paddingLeft:"4px",paddingTop:"4px",marginTop:"2px"}}
                        MenuProps={MenuProps}
                        onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                        
                        <ListSubheader>
                            <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            // ref={focusUsernameInputField}
                            inputRef={inputRef}
                            variant='standard'
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>  
                        {options.map((item,index) => (
                          <MenuItem key={item.name} value={item.instr}>{item.name}</MenuItem>
                        ))}
                    </Select> */}
                     <Select
                     getPopupContainer={(triggerNode) => triggerNode.parentNode} 
                        // variant="borderless"
                        suffixIcon={<ArrowDropDownIcon  style={{ color: 'white' }} />}
                        ref={selectRef}
                        showSearch
                        style={{
                          width: 280,
                          height:28,
                          maxHeight:28,
                          // marginTop:-4,
                          color:"#FFFFFF",
                          // backgroundColor: 'green'
                        }}
                       
                        placeholder="Search Instrument"
                        optionFilterProp="children"
                        defaultValue={"NIFTY"}
                        value={selectedInstrument}
                        dropdownStyle={{ backgroundColor: '#0d203f',color:'#00ff00',minWidth:"240px"}}
                        // onChange={handleSelectChange}
                        onChange={handleInstrumentChange}
                        onSearch={(value)=>setSearchText(value)}
                             filterOption={(input, option) => {
                          const words = input.trim().toLowerCase().split(' ');
                          return words.every(word => option.props.children.toLowerCase().includes(word));
                          
                          // return (
                          //   // option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          // );
                        }}
                      >
                      {options.map((item) => (
                        <Option key={item.instr} value={item.instr}  style={{fontSize:"12px"}}>

                          {item.name}
                        </Option>
                      ))}
                </Select> 
                    </Box>
                </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
        <FormControl variant="standard" size="small" sx={{ minWidth:"80%",ml:0,mt:1 }}>
        <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:0}}>
        <Box sx={{width:"272px",display:"flex",mr:1}}>
        <Typography sx={{marginTop:"4px",mr:5,fontSize:16,width:"150px"}}>Default Time Frame</Typography>
        <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Default time frame when the candlestick chart is opened from menu.</Typography>
                
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"5px"}}></InfoIcon>
          </HtmlTooltip>
          </Box>
                    <MUISelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedTimeFrame}
                        onChange={handleTimeframeChange}
                        onOpen={autoFocus}
                        label="Time Frame"
                        sx={{minWidth: 140,border:"1px solid #fff",borderColor:"#fff",paddingLeft:"4px",paddingTop:"4px",marginTop:"2px"}}
                        MenuProps={MenuProps}
                        // onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                       
                        {OF_TIME_FRAME.map((item,index) => (
                          <MenuItem key={item.key} value={item.value}>{item.displayText}</MenuItem>
                        ))}
                    </MUISelect>
                    </Box>
                </FormControl>
          </Box>
       
        {isPro2User?
        <>
        <Box display="flex" justifyContent="flex-start" sx={{mb:2,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>Rolling COT Panel #2</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Rolling COT (Upper Panel) [3,50]</Typography>
                         </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
              <TextField
                size="small"
                label=""
                type="number"
                value={rotatingCot2}
                onChange={handleChangeCot2}
                sx={{height:"25px",ml:1,padding:"3px"}}
                inputProps={{ min: ROTATING_COT_VAL["min"], max: ROTATING_COT_VAL["max"] }}
                // fullWidth
          />
        </Box>
         <Box display="flex" justifyContent="flex-start" sx={{mb:2,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>Rolling COT Panel #1</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Rolling COT (Lower Panel) [3,50]</Typography>
                         </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
              <TextField
                size="small"
                label=""
                type="number"
                value={rotatingCot}
                onChange={handleChangeCot}
                sx={{height:"25px",ml:1,padding:"3px"}}
                inputProps={{ min: ROTATING_COT_VAL["min"], max: ROTATING_COT_VAL["max"] }}
                // fullWidth
          />
        </Box>
        </>

        
        :
        <></>
        }
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Global Volume Profile</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Always show Global Volume Profile by Default</Typography>
                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                  {"It's very engaging. Right?"} */}
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>
        
        <Switch checked={showGlobalVolumeProfile} color="secondary"
            onChange={()=>setShowGlobalVolumeProfile(!showGlobalVolumeProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Global Profile Volume Numbers</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Show Volume Numbers for the Global Volume profile</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
        
          <Switch checked={showGlobalVolumeNumberProfile} color="secondary"
            onChange={()=>setShowGlobalVolumeNumberProfile(!showGlobalVolumeNumberProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>
        

      

    

    </Box>
  
    <Box sx={{display:"flex",mr:1,mt:0}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Global settings applicable across all charts</Typography>
                {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                {"It's very engaging. Right?"} */}
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"24px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
      
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:6,pr:2,borderRadius:5,width:"100%"}}>
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"0px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
        <Typography sx={{mr:1,mt:2,fontSize:14}}>Locale for Number Formatting</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Locale used for formatting large numbers across all pages</Typography>
                      Indian locale formats the numbers in Lakhs, Crores format. <br/>
                      US locale formats data in millions and billions
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"16px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
         <FormControlLabel value="en-In" 
          control={<Radio checked={numberFormatLocale === 'en-In'} onChange={handleLocaleChange} size="small"  sx={{mt:1}}/>} 
          label={<Typography sx={{fontSize:14, mt:1}}>Indian Format</Typography>} />
          <FormControlLabel value="en-Us" 
          control={<Radio checked={numberFormatLocale === 'en-Us'} onChange={handleLocaleChange} size="small" sx={{mt:1}} />} 
          label={<Typography sx={{fontSize:14, mt:1}}>US Format</Typography>} />
        
      </Box>
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>OI and Vol Data</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">OI and Volume data across all pages should be displayed in #Contracts vs Total value?</Typography>
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value="true" 
          control={<Radio checked={dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Contracts</Typography>} />
          <FormControlLabel value="false" 
          control={<Radio checked={!dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Total Value</Typography>} />
        
      </Box>

      {isPro2User?
      <>
       <Box display="flex" justifyContent="flex-start" sx={{ mb: 0, ml: 4, mt: 1, width: "100%" }}>
          <Box sx={{ width: "260px", display: "flex" }}>
            <Typography sx={{ mr: 1, mt: 1, fontSize: 14 }}>Browser Push Notification</Typography>
            <HtmlTooltip
              title={
                    <React.Fragment>
                       <Typography color="inherit">{TEXT_MSGS.INFO_BROWSER_NOTIFICATION_POP_UP}</Typography>
                    </React.Fragment>
                    }
            >
             <InfoIcon sx={{ marginTop: "6px" }}></InfoIcon>
            </HtmlTooltip>
           </Box>

            <Switch checked={browserPushNotification} color="secondary"
               onChange={() => handleNotificationChange(!browserPushNotification)}
              inputProps={{ 'aria-label': 'controlled' }}></Switch>

        </Box> 
        
        {isNotificationChanged?
        <Box sx={{ml:4}}>
          <Typography  variant="warningText"sx={{ mr: 1, mt: 0,ml:0, fontSize: 14 }}>
            {browserPushNotification?TEXT_MSGS.MSG_NOTIFICATION_ENABLED :TEXT_MSGS.MSG_NOTIFICATION_DISABLED} </Typography> 
            </Box>  
          :
          <></>
        }

        </>
        :<></>
        }  
   
    </Box>
    
    </Grid>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    
   
</Grid>
<SettingsFooterPopup saveSettings={saveSettings} discardChanges={discardChanges} resetDefault={resetToDefault}>

</SettingsFooterPopup>
</Box>    :
<>
<CircularProgress sx={{marginTop:"20%",marginLeft:"45%"}}/></>}
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
       </>
       </ConfigProvider>
       </Modal>
  )
}

export default CandleSettings
   